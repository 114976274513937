.breadcrumbs {
  margin: 0 0 20px 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  @include fontBuilder('Helvetica', 9px, 12px);

  li {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;

    a {
      text-decoration: underline;
    }
  }

  .separator {
    color: $text-dark;
  }
}
