/* podcasts.scss */

$featured-width: 394px;
$podcast-grid-width: 222px;
$podcast-grid-mar-right: 83px;

.podcast {
	h4 {
			font-weight: bold;
	}

	.date {
		@include fontBuilder("Helvetica", 10px, 12px);
		display: inline-block;
		font-weight: bold;
	}

	.podcast-title {
		@include fontBuilder("Helvetica", 17px, 20px);
		color: $podcast-text;
		font-weight: bold;
		padding-top: 8px;
		text-transform: initial;
	}

	.podcast-description-container {
		margin-right: 10px;
		max-width: 698px;
		width: 100%;
	}

	.podcast-description {
		@include fontBuilder("Helvetica", 14px, 24px);
		color: #262a32;
		margin: 0;
		padding: 0;
	}

	.description-links {
		@include fontBuilder("Helvetica", 14px, 24px);
		color: $podcast-orange;
	}

	.podcast-player {
		position: relative;
		max-width: $featured-width;

		.wordpress-podcast-player #player-controls {
			background: $white;
		}
	}

	.subscribe {
		@include fontBuilder("Helvetica", 10px, 12px);
		display: inline-block;
		font-weight: bold;
		margin-right: 10px;
	}

	ul.podcast-links {
		display: inline-block;
		margin: 0;
		padding: 0;

		li {
			color: #777;
			display: inline-block;
			list-style: none;
			margin-right: 5px;

			a {
				@include fontBuilder("Helvetica", 10px, 12px);
				color: #4496d2;
				display: inline-block;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.podcast-featured-image-container {
		max-width: $featured-width;
		width: 100%;
	}

	.podcast-featured-image {
		border: 1px solid #979797;
		margin: 0 0 10px;
		max-height: 470px;
		max-width: $featured-width;
		padding: 0;

		img {
			object-fit: cover;
			width: 100%;
		}
	}

	.podcast-grid {
		border: none;
		height: 285px;
		margin: 0 $podcast-grid-mar-right 62px 0;
		padding: 0;
		width: $podcast-grid-width;

		.podcast-thumbnail {
			margin-bottom: 10px;
			max-width: $podcast-grid-width;
			width: 100%;

			img {
				border: 1px solid #979797;
				max-width: $podcast-grid-width;
				width: 100%;
			}
		}

		.podcast-body {
			height: 110px;  //extra space added for IE
			position: relative;
			z-index: 10;

			.podcast-title {
				@extend .podcast-title;
				font-size: 14px;
				line-height: 17px;
				padding-top: 0;
			}

			.podcast-player-collection {
				@extend .podcast-player;
				bottom: 5px;
				left: 0;
				margin: 0;
				max-width: $podcast-grid-width;
				position: absolute;
				z-index: -1;
			}
		}

		&:nth-child(4n) {
			margin-right: 3px;
		}
	}

	.podcast-app-section {
		margin-bottom: 23px;
	}

	.podcast-help-message {
		font-weight: bold;
	}

	.podcast-border-right {
		border-right: 1px solid #dbd9d6;
	}

	ol.podcast-instructions {
		counter-reset: list;
		margin-left: -40px;

		> li {
			list-style: none;
			margin: 15px 0;

			&:before {
				content: counter(list) ")";
				counter-increment: list;
				margin-right: 5px;
			}
		}
	}

	.subscribe-links {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.podcast-subscribe {
		position: relative;
		text-align: center;

		ul.dropdown-menu {
			border-radius: 0;
			left: 490px;
			margin: 0 auto;
			width: 190px;
		}
	}

	.is-flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.grid-is-flush {
		justify-content: space-between;
	}

	.no-flex-wrap {
		flex-wrap: nowrap;
	}

	.podcast-list {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;

		&-title {
			margin-left: 15px;
		}

		&-view-all {
			margin-left: 35px;
		}

		.list-items {
			align-self: flex-end;
			height: 590px;
			margin-bottom: 35px;
			overflow-y: scroll;
			width: 250px;

			.podcast-grid {
				margin-bottom: 23px;
			}
		}
	}

	@media (max-width: $screen-lg-min) {
		.podcast-grid {

			&:nth-child(4) {
				margin-right: $podcast-grid-mar-right;
			}
		}

		.podcast-list {

			&-title {
				margin-left: -49px;
			}

			&-view-all {
				margin-left: -30px;
			}
		}
	}

	@media (max-width: $screen-md-min - 1px) {
		.podcast-featured-image {
			float: none;
			margin-top: 0;
			margin-bottom: 12px;
		}

		h1.mobile {
			margin-top: -5px;
			order: -2;
		}

		.podcast-border-right {
			border-right: none;
		}

		.remove-padding {
			padding-left: 0;
			padding-right: 0;
		}

		.subscribe-links {
			margin-top: 10px;
		}

		.is-flex {
			flex-direction: column;
		}

		.is-first-in-mobile {
			order: -1;
		}

		.podcast-list {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: $podcast-grid-width;

			&-title {
				margin: 0 1rem 0 0;
			}

			&-view-all {
				margin: 0 1rem 0 -1px;
			}
		}
	}
}
