@charset "UTF-8";

// Default Variables

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e909" !default;
$slick-next-character: "\e908" !default;
$slick-dot-character: "" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon.eot?tiftcx');
    src:url('../fonts/icomoon.eot?tiftcx#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?tiftcx') format('woff2'),
    url('../fonts/icomoon.ttf?tiftcx') format('truetype'),
    url('../fonts/icomoon.woff?tiftcx') format('woff'),
    url('../fonts/icomoon.svg?tiftcx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before,
.slick-next:before {
  @include icomoon();
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;

  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }

  &:before {
    content: $slick-prev-character;

    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;

  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }

  &:before {
    content: $slick-next-character;

    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -20px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 5px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      width: 60px;
      height: 5px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover, &:focus {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 60px;
        height: 5px;
        background-color: gray;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
