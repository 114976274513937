// Mixins

@mixin clear-fix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// make sure the parent div is position: relative
@mixin vertical-center() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin icomoon() {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
}

//font mixins

@mixin fontBuilder($family, $size, $lineHeight: normal, $letterSpacing: 0.05em, $style: normal) {
  font-family: $family, sans-serif;
  font-size: $size;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  font-style: $style;
}

// Position Assistance
@mixin borderHelper($color: #f00) {
    background-color: #FFB6C1;
    border: 1px dashed $color;
}