.gform_wrapper {
  margin: 0;

  .gform_fields {
    padding: 0;
  }
}

.newsletter-page {
	margin-top: 20px;

	.gfield {
		@extend .form-group;

		.ginput_container .name_first input, .ginput_container input {
			@extend .form-control;
			border-radius: 0;
			width: 400px;
		}
	}

	#gform_submit_button_4 {
		@extend .btn;
		@extend .btn-default;
		margin: 50px 0 25px;

		&:hover {
			background-color: #22608e;
			border-color: #0e293c;
			color: #fff;
			text-decoration: none;
		}
	}
}