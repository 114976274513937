/*********************************/
/**   Template Display How To   **/
/*********************************/

.display-how-to-subtitle {
	@extend .decorator-subtitle;
}

.simple-displays {
	color: $brand-red;
}

.mid-level-displays {
	color: $brandBlue;
}

.challenging-displays {
	color: $brandGreen;
}

.displays-extra-rows {
	margin-top: -45px;
}

.other-displays-title {
	margin: 0 !important;
}

.simple-display-carousel {
	display: none; // initial hide until loaded
	height: 100%;
	max-height: 282px;
	max-width: 510px;
	width: 100%;

	.slick-slide {
		img {
			max-width: 100%;
			width: 100%;
		}
	}
}

.simple-display-carousel-two {
	@extend .simple-display-carousel;
	// border: 1px dashed red;
	max-height: 352px;
	max-width: 641px;

	.slick-slide {
		img {
			max-width: 100%;
			width: 100%;
		}
	}
}

.display-video-container {
	padding-left: 30px;

	.display-video {
		#videoContainer {
			#vid-responsive {
				height: 260px !important;
				width: 465px !important;
			}

			@media (max-width: $screen-md-max) {
				#vid-responsive {
					height: 283px !important;
					width: 503px !important;
				}
			}

			@media (max-width: $screen-xs-max) {
				#vid-responsive {
					height: 30vh !important;
					max-width: 503px !important;
					width: 100% !important;
				}
			}

			@media (max-width: 565px) {
				#vid-responsive {
					height: 25vh !important;
				}
			}

			@media (max-width: 480px) {
				#vid-responsive {
					height: 22vh !important;
				}
			}
		}
	}
}

.industry-know-how .display-video-container {
	padding-left: 30px;

	.display-video {
		#videoContainer {
			#vid-responsive {
				height: 371px !important;
				width: 408px !important;
			}

			@media (max-width: $screen-md-max) {
				#vid-responsive {
					height: 283px !important;
					width: 503px !important;
				}
			}

			@media (max-width: $screen-xs-max) {
				#vid-responsive {
					height: 30vh !important;
					max-width: 503px !important;
					width: 100% !important;
				}
			}

			@media (max-width: 565px) {
				#vid-responsive {
					height: 25vh !important;
				}
			}

			@media (max-width: 480px) {
				#vid-responsive {
					height: 22vh !important;
				}
			}
		}
	}
}

.display-links {
	background-color: $brandBlueAlt;
	height: 100%;
	margin: 0 0 15px 0;
	max-width: 510px;
	min-height: 114px;
	padding: 15px;
	width: 100%;

	.display-links-header {
		margin: 0 0 15px 0;
	}

	.display-links-content {
		font-size: 11px;
		margin: 0 -25px 0 25px;
	}
}

.other-video-carousel {
	height: 145px;
	max-width: 470px;
	margin: 0 0 0 20px;
	padding: 0;
	width: 100%;

	/* Custom Slider Styles */

	.slick-slide {
		margin: 0 13px 20em;
		outline: none;
		text-align: center;

		a {
			border: none;
			outline: none;

			img {
				max-width: 100%;
				width: 100%;
			}
		}
	}
}

.display-other-videos {
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 93%;
}

.other-videos-title {
	@include fontBuilder("Helvetica", 11px, 13.2px);
}

.other-displays-border {
	border-bottom: 1px solid $text-light;

	&:last-of-type {
		border: none;
	}
}

@media (max-width: $screen-lg-min) {
	.simple-display-carousel {
		margin-left: 40px;
		max-width: 503px;
		width: 100%;
	}

	.display-links {
		margin-left: 40px;
		max-width: 503px;
		width: 100%;
	}

	.other-video-carousel {
		height: 145px;
		margin: 0px 0 0 21px;
		padding: 0;
		width: 91%;

		/* Custom Slider Styles */

		.slick-slide {
			margin: 0 10px 20em;
			width: 118px !important;
		}
	}

	.display-other-videos {
		margin: 0 0 0 40px;
		width: 100%;
	}
}

@media (max-width: $screen-md-min) {
	h2 {
		margin: 20px 0 0;
	}

	.other-videos-heading {
		margin-left: 15px;
	}

	.displays .decorator-placement-tiles {
		margin-top: 20px;
	}

	.display-links,
	.display-other-videos {
		margin-left: 15px;
	}

	.other-video-carousel {
		width: 63%;

		.slick-slide {
			margin: 0 19px 20em;
		}
	}
}

@media (max-width: $screen-sm-min) {
	.simple-display-carousel {
		margin-left: 0;
	}

	.display-links {
		.display-links-content {
			margin: 0;
		}
	}

	.other-video-carousel {
		max-width: 461px;
		width: 100%;
	}

	.display-other-videos {
		max-width: 706px;
		width: 100%;
	}
}

@media (max-width: 565px) {
	.other-video-carousel {
		max-width: 79vw;

		.slick-slide {
			margin: 0 7vw 20em;
			width: 140px !important;
		}
	}
}

@media (max-width: 520px) {
	.other-video-carousel {
		.slick-slide {
			margin: 0 6vw 20em;
			width: 140px !important;
		}
	}
}

@media (max-width: 480px) {
	.other-video-carousel {
		max-width: 78vw;

		.slick-slide {
			margin: 0px 23.5vw 20em;
			width: 143.39px !important;
		}
	}

	.displays-small-screen-crash {
		margin-right: 5px;
	}
}

@media (max-width: 420px) {
	.other-video-carousel {
		max-width: 75vw;

		.slick-slide {
			margin: 0px 22vw 20em;
			width: 133.52px !important;
		}
	}
}

@media (max-width: 380px) {
	.other-video-carousel {
		max-width: 72vw;

		.slick-slide {
			margin: 0px 17vw 20em;
			width: 143.48px !important;
		}
	}
}

@media (max-width: 340px) {
	.other-video-carousel {
		max-width: 69vw;

		.slick-slide {
			margin: 0px 15vw 20em;
			width: 131.9px !important;
		}
	}
}
