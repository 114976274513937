.ginput_complex,
.clear-multi,
.ginput_full,
.gf_list_2col,
.gf_list_3col,
.gf_list_4col,
.gf_list_5col,
.gf_list_inline,
.gf_page_steps,
.ui-datepicker-header {
	@include form-clearfix;
}

.gform_wrapper form {
	@include form-spacing(margin-bottom, $form-space);
}

.gform_heading {
	@include form-spacing(margin-bottom, $form-space * 2);
	@include form-spacing(padding-bottom, $form-space);
	border-bottom: 1px solid lighten($form-muted, 25%);

	.gform_title {
		@include form-spacing(margin-bottom, $form-space);
	}
}

.gsection {
	@include form-spacing(margin-bottom, $form-space);
	@include form-spacing(padding-bottom, $form-space);
	border-bottom: 1px solid $form-muted;

	.gsection_title {
		@include form-spacing(margin-bottom, $form-space);
	}
}

.gfield_required {
	color: $form-required;
	padding-left: 2px;
}

.ginput_complex label {
	font-weight: normal;
}

.gform_fields {
	margin: 0;
	list-style: none;
}

.gfield {
	@include form-spacing(margin-bottom, $form-space);
}

.gfield_description {
	@include form-font-size(14px);
	font-style: italic;
}

.validation_message {
	font-style: normal;
}

.gfield_checkbox,
.gfield_radio {
	list-style: none;
	margin: 0;

	input {
		@include form-spacing(margin-right, 4px);
	}
}

.gfield_radio {
	input[type="text"] {
		width: auto;
		display: inline-block;
	}
}

.ginput_complex {
	.ginput_left {
		width: 49%;
		float: left;
		clear: left;
	}

	.ginput_right {
		width: 49%;
		float: right;
		clear: right;
	}
}

.name_prefix {
	width: 10%;
	float: left;
	margin-right: 1%;
}

.name_first {
	width: 39%;
	float: left;
	margin-right: 1%;
}

.name_last {
	width: 39%;
	float: left;
	margin-right: 1%;
}

.name_suffix {
	width: 9%;
	float: right;
}

input.datepicker_with_icon {
	@include form-spacing(margin-right, $form-space);
	display: inline-block;
	width: auto;
}

.gfield_date_month,
.gfield_date_dropdown_month {
	display: inline-block;
	width: auto;
	margin-right: 1%;
}

.gfield_date_day,
.gfield_date_dropdown_day {
	display: inline-block;
	width: auto;
	margin-right: 1%;
}

.gfield_date_year,
.gfield_date_dropdown_year {
	display: inline-block;
	width: auto;
}

.gfield_time_hour {
	float: left;
	margin-right: 1%;

	input {
		width: inherit;
		display: inline-block;
	}

	label { display: block; }
}

.gfield_time_minute {
	width: 30%;
	float: left;
	margin-right: 1%;
}

.gfield_time_ampm {
	width: 20%;
	float: left;
	margin-right: 1%;
}

.gfield,
.gform_footer {
	clear: both;
}

.gf_left_half {
	width: 49%;
	float: left;
	clear: left;
}

.gf_right_half {
	width: 49%;
	float: right;
	clear: right;
}

.gf_left_third {
	clear: none;
	width: 32.5%;
	float: left;
	margin-right: 1.25%;
}

.gf_middle_third {
	clear: none;
	width: 32.5%;
	float: left;
}

.gf_right_third {
	clear: none;
	width: 32.5%;
	float: right;
}

.gf_inline {
	width: auto;
	display: inline-block;
	margin-right: 1%;
	vertical-align: top;
}

.gf_list_2col li {
	width: 50%;
	float: left;
}

.gf_list_3col li {
	width: 33%;
	float: left;
}

.gf_list_4col li {
	width: 25%;
	float: left;
}

.gf_list_5col li {
	width: 20%;
	float: left;
}

.gf_list_inline li {
	display: inline-block;
	margin-right: 1%;
}

.gf_list_height_25 li {
	height: 25px;
}

.gf_list_height_50 li {
	height: 50px;
}

.gf_list_height_75 li {
	height: 75px;
}

.gf_list_height_100 li {
	height: 100px;
}

.gf_list_height_125 li {
	height: 125px;
}

.gf_list_height_150 li {
	height: 150px;
}

.gfield_list {
	margin: 0;

}

.gfield_list > tbody > tr:nth-child(odd) > td,
.gfield_list > tbody > tr:nth-child(odd) > th {
	@include form-spacing(padding-right, $form-space);
	background: none;
	line-height: normal;
	padding: 0;
	border: 0;
}

.gfield_list > thead > tr > th {
	border: 0;
}

.gf_scroll_text {
	padding: 12px;
	border: 1px solid #cecece;
	height: 180px;
	overflow: auto;

	p:last-of-type {
		margin-bottom: 0;
	}
}

.gf_hide_ampm {
	.gfield_time_ampm {
		display: none;
	}
}

.gf_hide_charleft {
	.charleft {
		display: none;
	}
}

.gf_page_steps {
	@include form-spacing(margin-bottom, $form-space);
	line-height: 1;
}

.gf_step {
	display: inline-block;
	opacity: .2;
	margin-right: 1%;

	&:last-of-type {
		margin-right: 0;
	}
}

.gf_step_active {
	opacity: 1;
}

.gf_step_number {
	font-weight: bold;
	font-size: 150%;
}

.gfield_error {
	padding: $form-space;
	border: 1px solid #eee;
	border-left-width: 5px;
	border-left-color: #d9534f;

	> label,
	.validation_message {
		color: $input-error;
	}

	input,
	textarea,
	select {
		border-color: $input-error;

		&:focus {
			border-color: darken($input-error, 10%);
		}
	}
}

// honeypot field, hide it from human beings
.gform_validation_container {
	display: none;
	position: absolute;
	left: -9000px;
}

.ui-datepicker {
	background: $white;
	border: 1px solid $gray;
}

.ui-datepicker-prev {
	width: 20%;
	float: left;
}

.ui-datepicker-next {
	width: 20%;
	float: right;
}

.ui-datepicker-title {
	width: 60%;
	float: left;

	select {
		width: auto;
		display: inline-block;
	}
}
