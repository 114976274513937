footer.mainFooter {
  .footer-top {
    position: relative;
    min-height: 69px;
    background-color: $footer-bg;
    border-top: 1px solid $footer-border;
    border-bottom: 1px solid $footer-border;

    .connect {
      @include fontBuilder('Helvetica-bold', 18px, 22px);
    }

    .spacer {
      margin: 0 15px;

      @media (min-width: $screen-sm-min) {
        display: block;
      }

      @media (min-width: $screen-md-min) {
        display: inline;
      }
    }
  }

  .footer-links {
    text-transform: uppercase;
    margin: 15px 0;

    @media (min-width: $screen-sm-min) {
      @include vertical-center();
      margin: 0;
    }

    ul {
      margin: 0;
    }

    .label {
      text-transform: uppercase;
    }

    i {
      font-size: 20px;
    }
  }

  .links-feedback {
    display: inline-block;
    margin: 15px 0;

    @media (min-width: $screen-sm-min) {
      float: right;
      margin: 5px 60px 0 0;
    }
  }

  .footer-form {
    margin: 15px 0 0 0;

    @media (min-width: $screen-sm-min) {
      float: right;
      margin: 0;
    }
  }

  .gform_wrapper {
    width: 300px;
    margin: 0;

    .gform_body {
      .gfield_label {
        color: $text-dark;
      }

      .ginput_container {
        input {
          width: 70%;
          font-size: 10px;
          height: 34px;
        }

        @media (min-width: $screen-sm-min) {
          margin-bottom: 0;

          input {
            width: 200px;
          }
        }

        @media (min-width: $screen-lg-min) {
          margin-bottom: 10px;

          input {
            width: 200px;
          }
        }
      }
    }

    .gform_footer.top_label {
      float: right;
      margin: -40px 5px 0 0;

      @media (min-width: $screen-lg-min) {
        margin: -43px 5px 0 0;
      }

      .gform_button {
        height: 33px;
        padding: 7px 5px 6px 5px;
        font-size: 12px;
      }
    }
  }

  #gform_confirmation_wrapper_3 {
    margin: 20px 0 0 0;
    color: $brand-red;
  }

  .footer-bottom {
    margin: 10px 0;

    .bottom-copyright {
      display: inline-block;
      @include fontBuilder('Helvetica', 9px, 10.8px);
      color: $text-light;
    }

    .bottom-links {
      display: inline-block;
      @include fontBuilder('Helvetica', 9px, 10.8px);
      color: $text-light;

      &:hover,
      &:focus {
        color: $linkHoverBlue;
        text-decoration: underline;
      }
    }
  }
}
