.btn {
  padding: 8px 16px;
  border-radius: 0px;
  border-color: $brandBlue;
  text-transform: uppercase;
}

.btn-info {
  border-color: $white;
  background-color: $linkHoverBlue;
}

.btn-inline {
  @include fontBuilder('Helvetica', 11pt, 15pt, 0.05em);
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
}

a {
  &:hover,
  &:focus {
    color: $linkHoverBlue;
    text-decoration: none;
  }

  &.btn {
    &:hover,
    &:focus {
      background-color: darken($linkHoverBlue, 20%);
	  border-color: darken($linkHoverBlue, 40%);
	  color: $white;
      text-decoration: none;
    }
    &.btn-inline{
        &:hover,
        &:focus {
            background-color: $white;
                border-color: $white;
                color: $brandBlue;
            text-decoration: none;
        }
    }
  }
}