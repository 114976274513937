/*********************************/
/** Template Carousel           **/
/*********************************/
.template-carousel {
	display: none; // initial hide until loaded
}

/*********************************/
/** Template Vertical Tile      **/
/*********************************/
.template-verticalTile {
	margin: 0 0 30px 0;
	border: 1px solid $tileBorderGray;

	.verticalTile-imageContainer {
		img {
			max-width: 100%;
			width: 100%;
		}
	}

	.verticalTile-textContainer {
		position: relative;
		padding: 15px 10px 55px 10px; // bottom padding includes 20px from link bottom position
		min-height: 185px;

		.textContainer-link {
			position: absolute;
			bottom: 15px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
		}
	}

	&:hover {
		border-color: $brandBlue;
		cursor: pointer;

		h2,
		p,
		a {
			color: $brandBlue;
		}
	}

	&.verticalTile_alt {
		border: none;
		margin: 0;

		.verticalTile-imageContainer {
			border: 1px solid $tileBorderGray;
		}

		.verticalTile-textContainer {
			padding: 10px 0;
			min-height: auto;
		}
	}
}

/*********************************/
/** Template Vertical Tile      **/
/*********************************/
.template-upcomingList {
	.upcomingList-headline {
		border-bottom: 1px solid $dividerGray;
		text-align: center;
	}

	.upcomingList-body {
		overflow-y: scroll;
		max-height: 240px;

		@media (min-width: $screen-sm-min) {
			max-height: 290px;
		}

		.body-item {
			padding: 15px 0;
			border-bottom: 1px solid $dividerGray;

			&:last-of-type {
				border: none;
			}

			.item-iconBG {
				height: 25px;
				width: 25px;
				padding: 5px;

				&.users {
					background-color: $iconBG-user;
				}

				&.book {
					background-color: $iconBG-book;
				}

				&.video-camera {
					background-color: $iconBG-video;
				}

				&.mic {
					background-color: $iconBG-mic;
					text-align: center;
				}

				a {
					color: $icon-white;

					&:hover,
					&:focus {
						color: #000;
					}
				}
			}

			.media-body {
				p {
					margin: 0;
				}
			}
		}
	}
}

/*********************************/
/** Template Glossary Bar       **/
/*********************************/

.template-glossaryBar {
	.glossaryBar-headline {
		margin: 27px 0 0 0;
	}

	.glossaryBar-glossaryTable {
		width: 100%;
		margin: 19px 0 30px 0;

		.glossaryTable-cell {
			width: 3.4%;
			text-transform: uppercase;

			@include fontBuilder("Helvetica-Bold", 11px, 16.8px);

			@media (min-width: $screen-md-min) {
				@include fontBuilder("Helvetica-Bold", 14px, 16.8px);
			}
		}

		.glossaryTable-cell,
		.glossaryTable-spacer {
			text-align: center;
		}
	}
}

/*********************************/
/** Template Education Tile     **/
/*********************************/
.template-educationTile {
	margin: 30px 0 30px 0;
}

/*********************************/
/** Template Media Tile         **/
/*********************************/
.template-mediaTile {
	margin: 0 0 15px 0;

	.mediaTile-imageContainer {
		&.imageContainer_pdf {
			img {
				@media (min-width: $screen-sm-min) {
					max-height: 126px;
				}
			}
		}
	}

	.mediaTile-textContainer {
		margin: 5px 0 0 0;
		@include fontBuilder("Helvetica", 11px, 15px);
	}
}

/************************************/
/** Template Media Tile Alt **/
/************************************/
.template-mediaTileAlt {
	margin: 0 0 30px 0;

	.mediaTileAlt-imageContainer {
		display: table-cell;
		vertical-align: top;
		padding-right: 15px;

		img {
			max-width: 150px;
		}
	}

	.mediaTileAlt-textContainer {
		display: table-cell;
		vertical-align: top;
		margin: 5px 0 0 0;
		@include fontBuilder("Helvetica", 11px, 15px);
	}
}

/*********************************/
/** Template Media Link         **/
/*********************************/
.template-mediaLink {
	margin: 0 0 5px 0;

	.mediaLink-textContainer {
		padding: 3px 0 0 0;

		p {
			margin: 0;
			@include fontBuilder("Helvetica", 11px, 15px);
		}
	}
}

/***********************************/
/** Template Case Study Single    **/
/***********************************/
.template-caseStudySingle {
	img {
		@media (min-width: $screen-sm-min) {
			max-width: 600px;
		}
	}
}

/*********************************/
/** Template caseStudy Tile     **/
/*********************************/
.template-caseStudyTile {
	margin: 0 0 30px 0;
	padding: 0 0 30px 0;
	border-bottom: 1px solid $dividerGray;

	&:last-of-type {
		border: none;
	}

	.caseStudyTile-imageContainer {
		.media-object {
			background-size: cover;
			background-position: center center;
			height: 150px;
			width: 200px;

			a {
				display: block;
				height: 100%;
				width: 100%;
			}
		}
	}
}

/*********************************/
/** Template caseStudy Related  **/
/*********************************/
.template-caseStudyRelated {
	margin: 0 0 15px 0;
	padding: 15px;
	background-color: $relatedContainerBg;

	.caseStudyRelated-header {
		margin: 0 0 15px 0;
	}

	.caseStudyRelated-container {
		margin: 0 0 15px 0;

		&:last-of-type {
			margin: 0;
		}

		.container-icon {
			display: table-cell;
			vertical-align: top;
			padding: 0 5px 0 0;
		}

		.container-link {
			display: table-cell;
			vertical-align: top;
		}
	}
}

/*********************************/
/** Template GlossaryList       **/
/*********************************/
.template-glossaryList {
	.glossaryList-term {
		color: $brandBlue;
		text-transform: uppercase;
	}

	.glossaryList-definition {
		margin: 0 0 0 5px;
	}
}

/*********************************/
/** Template Word Of The Week   **/
/*********************************/
.template-wordOfWeek {
	margin: 30px 0 0 0;

	@media (min-width: $screen-md-min) {
		margin: 0;
	}

	.wordOfWeek-term {
		margin: 0;
	}

	.wordOfWeek-phonetic {
		margin: 0;
	}

	.wordOfWeek-partOfSpeach {
		margin: 0 0 15px 0;
	}

	.wordOfWeek-body {
		p {
			@include fontBuilder("Garamond", 11pt, 15pt);
		}
	}

	.wordOfWeek-message {
		p {
			@include fontBuilder("Garamond", 11pt, 15pt);
		}
	}
}

/*********************************/
/** Template Brand Grid         **/
/*********************************/
.template-brandGrid {
	margin: 0 0 30px 0;
	border-bottom: 1px solid $dividerGray;

	&:last-of-type {
		border: none;
	}

	.brandGrid-headline {
		margin: 0 0 25px 0;
	}

	.brandGrid-imageContainer {
		position: relative;
		height: 60px;
		max-height: 60px;
		margin: 0 0 73px 0;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.disabled {
	color: $lightGray10;
}

/**************************************/
/** Template Seasonal Marketing Tile **/
/**************************************/
.template-seasonalMarketingTile {
	height: 500px;
	margin: 0 0 30px 0;

	.seasonalMarketingTile-imageContainer {
		margin: 0 0 15px 0;
	}

	.seasonalMarketingTile-textContainer {
		.textContainer-headline {
			margin: 0 0 5px 0;
		}

		.textContainer-body {
			margin: 0 0 15px 0;

			p {
				line-height: 16px;
			}
		}
	}
}

/**************************************/
/** Template Seasonal Marketing Tile **/
/**************************************/
.template-styleNetworkTile {
	.styleNetworkTile-imageContainer {
		margin: 0 0 15px 0;
	}

	.styleNetworkTile-textContainer {
		margin: 0 0 30px 0;

		.textContainer-headline {
			margin: 0 0 5px 0;
		}

		// .textContainer-body {
		// }
	}

	&.styleNetworkTile_single {
		.styleNetworkTile-imageContainer {
			@media (min-width: $screen-sm-min) {
				display: table-cell;
				vertical-align: top;
				padding-right: 15px;
				margin: 0 0 15px 0;
			}
		}

		.styleNetworkTile-textContainer {
			margin: 30px 0 0 0;

			@media (min-width: $screen-sm-min) {
				display: table-cell;
				vertical-align: top;
				margin: 0 0 15px 0;
			}
		}
	}
}

/*******************************/
/** Template Fabric Blog Tile **/
/*******************************/
.template-fabicBlogTile {
	margin: 0 0 30px 0;
	padding: 0 0 30px 0;
	border-bottom: 1px solid $dividerGray;

	&:last-of-type {
		border: none;
	}

	.fabicBlogTile-body {
		.body-imageContainer {
			margin: 0 0 15px 0;

			a {
				img {
					display: block;
					max-width: 100%;
					width: 100%;
				}
			}
		}

		.body-textContainer {
			.textContainer-body {
				@include fontBuilder("Garamond", 11pt, 15pt);
			}

			.textContainer-date {
				margin: 0 0 15px 0;
				@include fontBuilder("Garamond", 11pt, 15pt);
			}

			.textContainer-byline,
			.textContainer-bylineTitle {
				@include fontBuilder("Garamond", 11pt, 15pt);
			}

			.textContainer-bylineTitle {
				margin: 0 0 15px 0;
			}
		}
	}

	.fabicBlogTile-footer {
		@include clear-fix();

		.footer-continue {
			margin: 12px 0 0 0;
		}

		.footer-meta {
			margin: 15px 0;
			color: $text-light;
			@include fontBuilder("Helvetica-bold", 11px, 13.2px);

			.meta-label {
				text-transform: uppercase;
			}

			a {
				color: $text-light;
				text-decoration: underline;
			}
		}
	}
}

/*******************************/
/** Template Related Stories  **/
/*******************************/
.template-relatedStories {
	.relatedStories-item {
		max-width: 220px;
		margin: 0 0 30px 0;

		.item-image {
			margin: 0 0 15px 0;
		}

		.item-headline {
			text-transform: uppercase;
			@include fontBuilder("Helvetica-Bold", 11px, 13.2px);
		}
	}
}

/*******************************/
/** Template Add This         **/
/*******************************/
.template-addthis {
	.addthis-label {
		margin: 0;
		@include fontBuilder("Helvetica", 11px, 13.2px);
	}
}

/*******************************/
/** Template Webcast Single   **/
/*******************************/
.template-webcastsSingle {
	.webcastsSingle-subhead {
		margin: 0 0 15px 0;
	}

	.webcastsSingle-videoContainer,
	.webcastsSingle-pdfContainer {
		margin: 0 0 15px 0;
	}

	.webcastsSingle-videoContainer {
		#videoContainer {
			#vid-responsive {
				height: 422px !important;
				width: 750px !important;
			}

			@media (max-width: $screen-md-max) {
				#vid-responsive {
					height: 270px !important;
					width: 480px !important;
				}
			}

			@media (max-width: $screen-xs-min - 1) {
				#vid-responsive {
					height: 180px !important;
					width: 310px !important;
					padding-right: 20px;
				}
			}
		}
	}

	.webcastsSingle-body {
		margin: 0 0 30px 0;
	}

	.webcastsSingle-social {
		margin: 0 0 30px 0;

		p {
			margin: 0 0 5px 0;
			text-transform: uppercase;
			@include fontBuilder("Helvetica", 11px, 13.2px);
		}
	}

	.webcastsSingle-related {
		margin: 0 0 30px 0;
	}

	.webcasts-otherWebcasts {
		.otherWebcasts-container {
			height: 350px;
			overflow-x: hidden;
			overflow-y: scroll;
			@media (min-width: $screen-sm-min) {
				height: 590px;
			}
		}
		.otherWebcasts-link {
			margin: 15px 0 0 0;
			@include fontBuilder("Helvetica-bold", 14px, 13.2px, 1px);
			text-transform: uppercase;
		}
		.allWebcasts-link {
			@extend .otherWebcasts-link;
			@include fontBuilder("Helvetica", 12px, 14.4px);

			.btn {
				font-size: 12px;
			}
		}
	}
}

/*******************************/
/** Template Webcast Tile     **/
/*******************************/
.template-webcastTile {
	padding: 0 0 15px 0;
	margin: 15px 0 0 0;
	border-bottom: 1px solid $dividerGray;

	&:last-of-type {
		border: none;
	}

	.webcastTile-imageContainer {
		img {
			max-width: 114px;
		}
	}

	.webcastTile-textContainer {
		p {
			@include fontBuilder("Helvetica", 11px, 15px);
		}
	}
}

/*********************************/
/** Template webcasts Related   **/
/*********************************/
.template-webcastsRelated {
	max-width: 600px;
	padding: 15px;
	background-color: $relatedContainerBg;

	.webcastsRelated-header {
		margin: 0 0 15px 0;
	}

	.webcastsRelated-container {
		@include clearfix();

		ul {
			margin: 0;
			padding: 0;
		}

		.container-item {
			margin: 0 0 15px 0;
			padding: 0 0 10px 0;
			border-bottom: 1px solid white;
			@include clearfix();

			&:last-of-type {
				margin: 0;
				padding: 0;
				border: none;
			}

			.item-meta {
				@media (min-width: $screen-sm-min) {
					float: left;
				}

				.meta-date {
					margin: 0;
					@include fontBuilder("Garamond-Bold", 11pt, 15pt);
				}

				.meta-label {
					@include fontBuilder("Garamond", 11pt, 15pt);
					p {
						margin: 0;
					}
				}
			}

			.item-button {
				@media (min-width: $screen-sm-min) {
					float: right;
				}
			}

			a {
				text-transform: uppercase;
				@include fontBuilder("Helvetica", 12px, 14.4px);
			}
		}
	}
}

/*******************************/
/** Template Fabric Blog Tile **/
/*******************************/

.template-postNav {
	margin: 15px 0;
	text-transform: uppercase;
	@include fontBuilder("Helvetica-Bold", 11px, 13.2px);
	@include clear-fix();
}

.textContainer-body {
	img.img-is-full-width {
		max-width: 100%;
		width: 100%;
	}
}

/**************************************/
/** Template Search Results Tile     **/
/**************************************/
.template-searchResultsTile {
	margin: 0 0 30px 0;
	padding: 0 0 30px 0;
	border-bottom: 1px solid $dividerGray;

	&:last-of-type {
		border: none;
	}

	.searchResultsTile-imageContainer {
		img {
			max-height: 200px;
		}
	}
}

.template-newsletterSignup {
	margin: 30px 0;
	padding: 10px;
	background-color: $lightGray20;

	.newsletterSignup-container {
		padding: 10px;
		border: 1px solid $tileBorderGray;

		.container-body {
			p {
				@include fontBuilder("helvetica", 12px, 14px);
			}
		}

		.container-form {
			@include clear-fix();

			label {
				display: none;
			}

			.gform_button {
				float: right;
			}
		}
	}
}

// Modal layouts

.modal-dialog {
	margin: 20% 10px;

	@media (min-width: $screen-sm-min) {
		margin: 20px auto;
	}

	@media (min-width: $screen-md-min) {
		margin: 100px auto;
	}
}

/*********************************/
/** Template Brand Modal        **/
/*********************************/
.template-brandModal {
	.modal-content {
		border-radius: 0;
	}

	.modal-header {
		border-bottom: 1px solid #000000;

		.close {
			font-size: 28px;
		}
	}

	.modal-body {
		.body-image {
			min-height: 1px;

			img {
				display: block;
				margin: 15px auto 30px auto;

				@media (min-width: $screen-sm-min) {
					margin: 0;
				}
			}

			@media (min-width: $screen-sm-min) {
				float: left;
				width: 35%;
			}
		}

		.body-content {
			position: relative;

			@media (min-width: $screen-sm-min) {
				float: left;
				width: 65%;
			}

			.content-description {
				margin: 0 0 15px 0;
				color: $text-light;
				@include fontBuilder("helvetica", 14px, 16.8px);
			}

			.content-link {
				@include fontBuilder("helvetica", 14px, 16.8px);
				margin: 0 0 30px 0;
				text-align: center;

				a {
					color: $text-light;
					display: inline-block;
					// margin: 0 auto;
					padding: 8px 30px;
					text-decoration: none;

					&:hover {
						color: $white;
					}
				}
			}
		}
	}
}

/*********************************/
/** Template Video Modal        **/
/*********************************/
.template-videoModal {
	#videoContainer {
		#vid-responsive {
			height: 100% !important;
			left: 0;
			position: absolute;
			top: 0;
			width: 100% !important;
		}
	}

	@media (min-width: $screen-sm-min) {
		.modal-dialog {
			width: 624px;
		}
	}

	.modal-content {
		border-radius: 0;
	}

	.modal-content {
		border-radius: 0;
	}

	.modal-header {
		border-bottom: 1px solid #000000;

		.close {
			font-size: 28px;
		}
	}

	.modal-body {
		padding: 0;
		position: relative;

		@media (min-width: $screen-sm-min) {
			height: 350px;
		}
	}

	#videoContainer {
		display: block;
		padding-bottom: 56.25%;
		position: relative;
		width: 100%;
	}

	.modal-footer {
		text-align: left;
	}
}

/*********************************/
/** Template industry Modal     **/
/*********************************/
.template-industryModal {
	.body-content {
		width: 80%;
		margin: 0 auto;
	}

	.modal-content {
		border-radius: 0;
	}

	.content-description {
		margin: 0 0 15px 0;
		color: $text-light;
	}

	.content-link {
		margin: 0 0 15px 0;

		a {
			color: $text-light;
			text-decoration: underline;
		}
	}

	.content-close {
		display: block;
		margin: 0 auto;
	}
	.modal-header {
		border-bottom: 1px solid #000000;

		.close {
			font-size: 28px;
		}
	}
}

body.terms,
body.privacy-policy {
	p {
		@include fontBuilder("Garamond", 12px, 16px);
	}

	h1 {
		@include fontBuilder("Helvetica-Bold", 16px, 19.2px);
	}

	h2 {
		@include fontBuilder("Helvetica-Bold", 11px, 13.2px);
		color: $brandBlue;
	}
}

.navigation {
	.nav-links {
		@include clear-fix();

		.nav-previous {
			float: left;
		}

		.nav-next {
			float: right;
		}
	}
}

//overriding plug-in css
body .scroll-back-to-top-wrapper {
	width: 64px;
	height: 90px;
	background-color: $white;
	color: $text-light;
	border-radius: 0px;
	border: 1px solid $text-light;

	.scroll-back-to-top-inner {
		i {
			position: absolute;
			top: 0;
			left: 0;
			width: 64px;
			padding: 10px;
			line-height: 1.2;
			text-align: center;
			line-height: 10px;

			&:before {
				display: block;
				content: "Back to Top";
				font-size: 12px;
				line-height: 1.2;
				text-transform: uppercase;
				@include fontBuilder("Helvetica", 11px, 13.2px);
			}

			&:after {
				display: block;
				margin: 20px 0 0 0;
				content: "\f0aa";
			}
		}
	}
}

.search-form.form-inline {
	margin-top: 30px;
}

body.post-type-archive-the-style-network {
	.styleLogo {
		display: block;
		margin: 0 auto 15px auto;
	}

	.trend-archive {
		a {
			text-decoration: underline;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

body.post-type-archive-fabric-blog {
	.fabricLogo {
		display: block;
		margin: 0 auto 35px auto;
	}

	.main {
		input[type="search"] {
			padding: 10px 50px;
			background-image: url(../images/search_icon.png);
			background-position: 5px 5px;
			background-repeat: no-repeat;
		}
	}
}

body.page-template-template-social-responsibility {
	.subhead {
		margin: 0 0 30px 0;
		@include fontBuilder("Garamond", 14px, 16.6px);
		color: $text-dark;
	}
}

.breadcrumbs {
	color: $text-light;

	a,
	.separator {
		color: $text-light;
	}
}

// Global Gravity Forms Overrides
#gform_1 {
	.gform_body {
		.gfield {
			@media (min-width: $screen-sm-min) {
				width: 40%;
				float: left;
				clear: none;
				margin: 0 30px 0 0;
			}

			&:last-of-type {
				width: auto;
				float: none;
				clear: both;
				margin: 0;
			}
		}

		.gfield_html {
			position: absolute;
			bottom: -50px;
			color: $brand-red;

			@media (min-width: $screen-sm-min) {
				bottom: -40px;
			}

			@media (min-width: $screen-md-min) {
				bottom: 0;
			}
		}

		.gfield_checkbox {
			padding: 0;

			label {
				text-transform: none;
				@include fontBuilder("Garamond-italic", 14px, 16.6px);
			}
		}

		.gfield > label {
			margin: 30px 0 0 0;
		}
	}

	.gform_footer {
		input {
			float: right;
			margin: 30px 0 0 0;
			background-color: $white;
			color: $text-dark;
			border: 1px solid $buttonBorderBlue;
			@include fontBuilder("Helvetica", 12px, 16.6px);
		}
	}
}

#gform_3 {
	.gform_button {
		background-color: $linkHoverBlue;
	}
}

body {
	.facetwp-type-checkboxes {
		max-height: 300px;
	}
}

.error404 {
	.wrap.container {
		margin: 0;
		padding: 0;
	}

	.error404-textContainer {
		margin: 50px 0 0 0;
		text-align: center;
	}

	h1 {
		@include fontBuilder("Helvetica-Bold", 30px, 36px, 0.05em);
		color: $brandBlue;
	}

	p {
		@include fontBuilder("Helvetica-Bold", 15px, 18px, 0.05em);
		margin: 0 0 15px 0;
	}

	.error404-bg {
		display: block;
		position: relative;
		width: 100%;
		background-size: cover;
		background-position: top center;
		padding-bottom: 33.33%;
	}
}

/*********************************/
/**   Template Decoration 101   **/
/*********************************/

.decorator-main-img {
	border: 2px solid #999;
	margin: 0 20px 0 0;
	padding: 0;
}

.decorator-subtitle {
	color: $text-dark;
	@include fontBuilder("Garamond", 16px, 20px);
	text-align: left;
}

.decorator-abstract-title {
	color: $linkHoverBlue;
	@include fontBuilder("Helvetica-Bold", 14px, 16.8px, 0.05em);
	margin-bottom: 10px;
	text-align: left;
	text-transform: uppercase;
}

.decorator-logo {
	margin: -15px 0 0;
	padding: 0;
}

.decorator-abstract-body {
	@extend .decorator-subtitle;
	color: $lightGray30;
}

.decorator-img-zoom {
	color: $lightGray30;
	font-size: 13px;
	font-style: normal;
	letter-spacing: 0rem;
	line-height: 24px;
	margin: 10px 0 0 50px;
}

.feature-video-container {
	height: 100%;
	margin-top: -70px;
	max-height: 200px;
	max-width: 250px;
	width: 100%;

	img {
		margin-bottom: 10px;
		width: 100%;
	}
}

.decorator-navlinks {
	margin-top: 40px;

	a {
		@extend .decorator-subtitle;
		font-size: 14px;
		line-height: 14.4px;
		text-transform: uppercase;

		&:hover {
			color: $linkHoverBlue;
		}
	}
}

.decorator-links {
	h3 {
		@extend .decorator-abstract-title;
		margin: 30px 0 0;
	}

	a {
		border-right: 1px solid $brandBlue;
		color: $icon-black;
		@include fontBuilder("Helvetica", 10px, 20px, 0rem);
		margin: 0 0 20px;
		padding: 0 10px 0 5px;

		&:hover {
			color: $linkHoverBlue;
		}

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			border: none;
		}
	}
}

.decorator-thumbnail-hack {
	width: 25%;
}

.decorator-thumb {
	margin: 10px 0;
	text-align: left;
	width: 36.666667%;

	img {
		border: 1px solid $lightGray40;
		display: block;
		float: left;
		height: 119px;
		margin: 0 14px 5px 0;
		padding: 0;
		width: 159px;
	}

	h4 {
		color: $icon-black;
		@include fontBuilder("Helvetica-Bold", 12px, 1.2rem, 0rem);
		margin: 0 0 10px;
		padding: 0;
		white-space: normal;
	}

	p {
		@extend h4;
		@include fontBuilder("Helvetica", 10px, 12px, 0rem);
		margin: 0;
	}
}

.decorator-placement-second-row,
.multiple-rows {
	margin-top: 42px !important;
}

.decorator-placement-tiles {
	color: $text-dark;
	margin: 0;
	text-align: left;

	&:nth-child(4n) {
		margin-right: -1px !important;
		padding-right: 0 !important;
	}

	h2 {
		@include fontBuilder("Helvetica-Bold", 16px, 19.2px, 0.05rem);
		text-transform: uppercase;
	}

	img {
		border: 1px solid $lightGray40;
		display: block;
		height: 158px;
		margin: 0 0 8px;
		padding: 0;
		width: 218px;
	}

	span {
		float: left;
		font-size: 14px;
		line-height: 1;
		margin: 0 5px 0 2px;
	}

	a {
		@include fontBuilder("Helvetica", 13px, 20px, 0rem);

		&:hover {
			color: $linkHoverBlue;
		}
	}
}

@media (max-width: $screen-md-max) {
	.decorator-placement-tiles {
		&:nth-child(2n) {
			margin-right: -1px !important;
			padding-right: 0 !important;
		}

		a {
			font-size: 11px;
		}
	}

	.feature-video-container {
		margin-top: initial;
		margin-bottom: 70px;
	}

	.decorator-img-zoom {
		margin: 10px 0 10px 45px;
	}
}

@media (max-width: 991px) {
	.decorator-title {
		margin: 20px 0 5px;
	}

	.decorator-logo {
		margin-bottom: 20px;
	}

	.decorator-placement-second-row,
	.multiple-rows {
		margin-top: auto !important;
	}

	.decorator-thumb {
		width: 39%;
	}

	.decorator-placement-tiles {
		h2 {
			margin-top: 30px;
		}
	}
}

@media (max-width: 780px) {
	.decorator-thumb {
		display: block;
		margin-left: auto;
		margin-right: auto;
		padding-left: auto;
		padding-right: auto;
		width: 88.66666%;

		h4 {
			margin: 10px 0;
		}
	}
}

@media (max-width: 480px) {
	.decorator-thumb {
		width: 100%;
	}
}

.banner-tile {
	.third-image {
		margin-top: 29px;
	}
}

@media (max-width: 991px) {
	.banner-tile {
		.auxillary-banners {
			.second-image,
			.third-image {
				display: block;
				float: none;
				margin: 20px auto 0;
				padding: 0;
			}
		}
	}
}

.multilined {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.is-vertical-centered {
	align-content: center;
	display: flex;
}
