// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
}
.aligncenter {
	display: block;
	margin: ($line-height-computed / 2) auto;
}
.alignleft,
.alignright {
	margin-bottom: ($line-height-computed / 2);
}
@media (min-width: $screen-sm-min) {
	// Only float if not on an extra small device
	.alignleft {
		float: left;
		margin-right: ($line-height-computed / 2);
	}
	.alignright {
		float: right;
		margin-left: ($line-height-computed / 2);
	}
}

// Captions
.wp-caption {
	@extend .thumbnail;
}
.wp-caption-text {
	padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
	@extend .sr-only;
	@extend .sr-only-focusable;
}

.make-pictures-responsive,
img.make-pictures-responsive {
	display: block;
	max-width: 100%;

	img {
		display: block;
		max-width: 100%;
	}
	a {
		img {
			display: block;
			max-width: 100%;
		}
	}
	p {
		img {
			display: block;
			max-width: 100%;
		}
	}
}
