@font-face {
	font-family: "Garamond";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/Garamond.eot") format("embedded-opentype");
	src: local("Garamond"), url("../fonts/Garamond.woff") format("woff");
}

@font-face {
	font-family: "Garamond-Bold";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/GaramondBold.eot") format("embedded-opentype");
	src: local("Garamond-Bold"),
		url("../fonts/GaramondBold.woff") format("woff");
}

@font-face {
	font-family: "Garamond-Italic";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/GaramondItalic.eot") format("embedded-opentype");
	src: local("Garamond-Italic"),
		url("../fonts/GaramondItalic.woff") format("woff");
}

@font-face {
	font-family: "Helvetica";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/HelveticaLTStd-Roman.eot") format("embedded-opentype");
	src: local("HelveticaLTStd-Roman"),
		url("../fonts/HelveticaLTStd-Roman.woff") format("woff");
}

@font-face {
	font-family: "Helvetica-Bold";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/HelveticaLTStd-Roman.eot") format("embedded-opentype");
	src: local("HelveticaLTStd-Bold"),
		url("../fonts/HelveticaLTStd-Bold.woff") format("woff");
}

@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?tiftcx");
	src: url("../fonts/icomoon.eot?tiftcx#iefix") format("embedded-opentype"),
		url("../fonts/icomoon.woff2?tiftcx") format("woff2"),
		url("../fonts/icomoon.ttf?tiftcx") format("truetype"),
		url("../fonts/icomoon.woff?tiftcx") format("woff"),
		url("../fonts/icomoon.svg?tiftcx#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}

// https://icomoon.io/

[class^="icon-"],
[class*=" icon-"] {
	font-family: "icomoon";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-users:before {
	content: "\e900";
}
.icon-magnifying-glass:before {
	content: "\e901";
}
.icon-minus:before {
	content: "\e90c";
}
.icon-plus:before {
	content: "\e90d";
}
.icon-chevron-down:before {
	content: "\e90e";
}
.icon-globe:before {
	content: "\e90b";
}
.icon-file-pdf-o:before {
	content: "\e90a";
}
.icon-book:before {
	content: "\e902";
}
.icon-video-camera:before {
	content: "\e903";
}
.icon-youtube-square:before {
	content: "\e904";
}
.icon-pinterest:before {
	content: "\e905";
}
.icon-twitter:before {
	content: "\e906";
}
.icon-facebook:before {
	content: "\e907";
}
.icon-chevron-right:before {
	content: "\e908";
}
.icon-chevron-left:before {
	content: "\e909";
}
