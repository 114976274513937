/***********************************************/
/** Template How to Video Sections and Search **/
/***********************************************/

.template-howToSearch {
	background-color: $brandGreen;
	margin: 2rem;
	margin-left: 0;
	max-width: 700px;
	min-width: 335px;
	opacity: 1;
	padding: 15px;
	@include clear-fix();

	@media (min-width: $screen-sm-min) {
		width: 700px;
	}

	@media (min-width: $screen-md-min) {
		margin: 30px auto;
	}

	@media (max-width: $screen-xs-min) {
		margin: 1.9rem 7px 1.9rem 0;
		padding: 13px 7px;
	}

	.howToSearch-keyword {
		margin: 0 0 15px 0;

		@media (min-width: $screen-sm-min) {
			float: left;
			margin: 0;
		}

		.input-group {
			width: 100%;

			i.facetwp-icon {
				left: 3px;
				right: auto;
			}

			@media (min-width: $screen-sm-min) {
				width: 334px;
			}
		}

		input.facetwp-search {
			min-width: 313px;
			width: 100%;
			height: 50px;
			padding: 1px 50px 0 35px;
			@include fontBuilder("Helvetica", 13px, 16px);

			@media (min-width: $screen-sm-min) {
				width: 334px;
			}
		}

		input[type="search"] {
			background-image: url(../images/search_icon.png);
			background-position: 10px 10px;
			background-repeat: no-repeat;
		}

		.btn {
			position: relative;
			left: 10px;
			width: 0;
			padding: 0;
			margin-top: 2px;
			margin-bottom: 0;
			border: 0;
			border-radius: 3px;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			background: none;
			color: $text-light;
			font-size: 30px;
		}
	}

	.howToSearch-select {
		float: left;
		margin: 0 0 0 5px;

		select {
			width: 200px;
			height: 49px;
			border: 0;
			padding: 11px 10px 10px;
			border: none;
			background: #ffffff
				url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
				no-repeat 170px center;
			-webkit-border-radius: 0px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			@include fontBuilder("Helvetica", 13px, 16px);
		}

		@media (max-width: $screen-xs-min) {
			width: 180px;
			margin-left: 0;
			margin-right: 10px;
			background: #ffffff
				url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
				no-repeat 150px center;
		}
	}

	.howToSearch-button {
		margin: 8px 0 0 20px;
		float: left;

		button {
			height: 36px;
			width: 104px;
			background-color: $buttonBgGray;
			color: $black;
			@include fontBuilder("Helvetica", 12px, 14.4px);
		}
	}

	.facetwp-facet {
		margin: 0;
	}
}

.howtolink {
	@include fontBuilder("Helvetica", 11px, 15px);

	&:hover,
	&:focus {
		color: $linkHoverBlue;
	}
}

.template-headlineButton {
	.headlineButton-headline {
		display: inline-block;
	}

	.headlineButton-button {
		display: inline-block;
		margin: 0 0 0 15px;
		@include fontBuilder("Helvetica-bold", 12px, 14.4px);
	}
}

.how-to-videos-slides {
	.how-to-vid-title {
		color: $text-dark;
		@include fontBuilder("Helvetica-bold", 16px, 19.2px, 2px);
	}

	.how-to-vid-subtitle {
		@extend .decorator-subtitle;
	}

	.btn-gray {
		background-color: $how-to-view-all;
		border: none;
		margin: 3px 0 5px;
		padding: 12px 20px 10px 15px;
		position: relative;
		z-index: 99;
	}

	img {
		border: 1px solid $download-links;
		display: block;
		height: auto;
		max-width: 100%;
	}

	a {
		border: none;

		img {
			border: 1px solid $download-links;
			display: block;
			height: auto;
			max-width: 100%;
		}
	}

	p {
		@include fontBuilder("Helvetica", 11px, 13px, normal);
		font-weight: 400;
	}

	&--carousel {
		max-width: 1140px !important;
		z-index: 9;

		.slick-arrow {
			z-index: 9;
		}

		button.slick-next.slick-arrow {
			right: 16px;
		}

		.slick-track {
			z-index: 9;
		}

		.slick-slide {
			margin: 0 50px 20em 13px !important;
			padding-left: 0px;
			padding-right: 0px;
			z-index: 9;

			a {
				img {
					height: auto !important;
					max-width: 100%;
				}
			}

			img {
				height: auto !important;
				max-width: 100%;
			}
		}
	}

	.carousel-container {
		position: relative;
		overflow-y: initial !important;
	}

	hr.section-separator {
		&:last-of-type {
			display: none;
		}
	}
}

@media (max-width: $screen-lg-min) {
	.carousel-tiles.slick-slide {
		margin: 0 50px 20em 15px !important;
	}
}

@media (max-width: $screen-md-min) {
	.mobile-view {
		padding-left: 24px !important;
	}

	.mobile-center {
		text-align: center;
	}
}

@media (max-width: $screen-xs-min) {
	.how-to-videos-slides .carousel-container {
		padding-left: 25px;
	}
}
