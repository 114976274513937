/*********************************/
/** Template Product Education  **/
/*********************************/

// vars
$carousel-image-border-color: #979797;
$carousel-lg-breakpoint: 1200px;

.product-categories {
	.template-mediaLink .mediaLink-textContainer {
		color: $download-links;
		font-family: "Helvetica";
		font-style: normal;

		p {
			color: $download-links;
			font-size: 14px;
		}
	}

	.more-class-link {
		color: $title-one;
		padding-left: 23px;
	}

	.media:first-child {
		margin-top: 25px;
	}

	i {
		color: $download-links;
	}

	&--section {
		border-bottom: 1px solid #d7d5cd;
		margin: 0 0 27px;
		padding: 0 0 22px;

		&--image--container {
			margin-top: 0px;
		}

		&--image {
			float: right;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	&--title {
		@include fontBuilder("Helvetica-Bold", 18px, 22px, 2.5px);
		margin: 0;
		padding: 0;

		a {
			color: inherit;
			@include fontBuilder("Helvetica-Bold", 18px, 22px, 2.5px);
		}
	}

	&--description,
	p {
		@include fontBuilder("Helvetica", 14px, 22px);
		margin: 11px 0 0;
		padding: 0;
	}

	&--heading-link {
		@include fontBuilder("Helvetica-Bold", 14px, 22px);
		font-weight: bold;
		margin: 0;
		padding: 0;

		a {
			color: $title-one;

			&:hover {
				color: inherit;
			}
		}
	}

	.title-1 {
		color: $title-one;

		a {
			color: $title-one;
		}
	}

	.title-2 {
		color: $title-two;

		a {
			color: $title-two;
		}
	}

	.title-3 {
		color: $title-three;

		a {
			color: $title-three;
		}
	}

	@media (max-width: $screen-md-min - 1px) {
		.fluid-float {
			float: left;
		}
	}

	@media (max-width: $screen-xs-min - 1) {
		&--section {
			&--image--container {
				margin-right: -10px;
			}

			&--image {
				display: inline-block;
				float: none;
			}
		}
	}

	@media (max-width: 330px) {
		&--section {
			&--image--container {
				margin-right: 0;
			}
		}
	}
}

.product-item, .how-to-videos-slides {
	.title {
		@include fontBuilder("Helvetica", 11px, 13px);
		margin: 5px 0 0;
		padding: 0;
	}

	.title-container {
		margin-right: -75px;
	}

	.decorator-intro-container {
		margin-left: 20px;
	}

	.decorator-title {
		letter-spacing: 2px;
	}

	.decorator-subtitle {
		font-size: 14px;
		margin-bottom: 12px;
	}

	.carousel-container {
		position: relative;
		overflow-y: hidden;
	}

	.slide-counter {
		background-color: #000;
		border: 1px solid #333;
		border-radius: 2px;
		color: #fff;
		display: block;
		height: 35px;
		left: 15px;
		opacity: 0.6;
		padding: 10px;
		position: absolute;
		top: -5px;
		width: 72px;
		z-index: 9999;
	}

	&--carousel {
		// border: 1px solid red;
		display: none; // initial hide until loaded
		height: 100%;
		margin-left: 20px;
		max-height: 165px;
		max-width: 1015px;
		width: 100%;

		.slick-arrow {
			top: 40%;
		}

		.slick-slide {
			margin: 0 25px 20em 15px;
			outline: none;
			text-align: left;
			width: 164px !important;

			a {
				border: none;
				outline: none;

				img {
					border: 1px solid $carousel-image-border-color;
					display: block;
					height: 141px;
					width: 177px;
				}
			}

			img {
				border: 1px solid $carousel-image-border-color;
				display: block;
				height: 141px;
				width: 177px;
			}
		}
	}

	&--main-section {
		&--video-container {
			padding: 12px 0 7px 40px;
			width: 62%;

			.product-item-video {
				margin-bottom: 10px;

				&:first-of-type {
					margin-bottom: 12px;
				}
			}

			&--primary-video {
				height: 100%;
				margin-top: 10px;
				margin-right: 0;
				max-height: 358px;
				max-width: 447px;
				padding-right: 0;
				width: 100%;
			}

			&--title {
				margin-top: 10px;
			}

			&--secondary-video {
				margin-top: 10px;
				margin-left: 21px;

				.product-item-video {
					img {
						height: 100%;
						max-height: 139px;
						max-width: 174px;
						width: 100%;
					}
				}
			}
		}

		.section-color {
			background-color: $background-blue;
			height: 41%;
			margin-bottom: 20px;
			margin-left: 35px;
			padding: 12px 12px 15px 41px;
		}

		&--nav-container {
			margin-left: 12px;
		}

		.nav-section {
			background-color: $download-links;
			color: $text-dark;
			@include fontBuilder("Helvetica-Bold", 16px, 19px, 2px);
			height: 189px;
			padding: 5px 0px;
			text-align: center;
			text-transform: uppercase;
			width: 236px;

			.text-bright {
				color: $white;
				@include fontBuilder("Helvetica-Bold", 20px, 24px, 2px);
				margin-top: 5px;
			}

			.fa-angle-right:before,
			.fa-angle-left:before {
				font-size: 25px;
				font-weight: 700;
			}
		}

		.next {
			margin-bottom: 27px;
		}
	}

	@media (max-width: $carousel-lg-breakpoint) {
		&--carousel {
			// border: 1px solid red;
			margin-left: 19px;
			max-width: 870px;

			.slick-slide {
				margin: 0 18px 20em;
				width: 182px !important;
			}
		}

		&--main-section {
			&--video-container {
				margin: 0 0 0 22px;

				&--secondary-video {
					.product-item-video > div {
						margin-left: -6px;
						padding-left: 0;
					}
				}
			}
		}
	}

	@media (max-width: $screen-md-max) {
		.decorator-subtitle {
			margin-left: initial;
		}

		.product-item--main-section--nav-container {
			margin-left: initial;
		}

		.product-item--main-section--video-container--secondary-video {
			margin-top: 30px;
		}
	}

	@media (max-width: $screen-sm-max) {
		.decorator-intro-container {
			margin-left: -8px;
		}

		&--carousel {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			height: auto;
			justify-content: flex-start;
			margin-bottom: 25px;
			margin-left: initial;
			margin-right: initial;
			margin-top: 10px;
			max-height: initial;
			max-width: 500px;
			padding: 0;
			position: relative;

			.slick-slide {
				a {
					img {
						max-width: 100%;
						width: 100%;
					}
				}

				img {
					max-width: 100%;
					width: 100%;
				}
			}

			.carousel-tiles {
				display: block;
				margin-bottom: 20px;
				margin-left: 7px;
				margin-right: 6px;
				padding: 0;
				width: 45%;
			}
		}

		img.carousel-img {
			border: 1px solid $carousel-image-border-color;
			display: block;
			height: 100%;
			margin: 7px 0;
			max-height: 141px;
			max-width: 177px;
			padding: 0;
			position: relative;
			width: 100%;
		}

		&--main-section {
			&--video-container {
				margin-bottom: 25px;
				margin-right: 20px;
				max-width: 415px;
				padding-left: initial;
				position: relative;
				width: 100%;

				&--primary-video,
				&--title,
				&--primary-video-thumbnail {
					padding-left: 30px;
				}

				&--secondary-video {
					margin-top: 25px;
					padding-left: 30px;
				}
			}

			.section-color {
				height: auto;
				margin-left: 6px;
				padding: 5px;
			}
		}

		.hidden-nav {
			max-width: 423px;
			padding-left: 5px;
			width: 100%;
		}
	}

	@media (max-width: $screen-xs-max) {
		&--carousel {
			width: initial;
		}

		.title {
			margin: 8px 0 8px;
			padding: 0;
			position: relative;
		}

		&--main-section {
			.title {
				margin: 12px 0 0;
				width: initial;
			}

			&--video-container {
				max-width: 415px;
			}

			.section-color {
				margin: 10px;
				padding: 5px;
			}
		}

		.hidden-nav {
			max-width: 415px;
		}
	}

	@media (max-width: 530px) {
		&--carousel {
			.carousel-tiles {
				img.carousel-img {
					height: initial;
					max-width: 100%;
				}
			}
			.carousel-tiles:last-of-type {
				margin-left: 36px;
			}
		}

		&--main-section {
			margin-right: 5px;
			padding-right: 15px;

			&--video-container {
				max-width: 433px;
			}

			.section-color {
				padding-right: 35px;
			}
		}

		.hidden-nav {
			max-width: 433px;
		}
	}
}
