// Sticky Footer
// https://css-tricks.com/snippets/css/sticky-footer/
// -------------------------

html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	height: 100vh;
}

@media (min-width: $screen-sm-min) {
	.page-wrap {
		min-height: 100%;
		margin-bottom: -140px;
	}
	.page-wrap:after {
		content: "";
		display: block;
	}
	.content-info,
	.page-wrap:after {
		height: 140px;
	}
}

.wrap {
	width: 100%;

	.main {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 40px;
	}
}

// Typography

h1 {
	@include fontBuilder("Helvetica-Bold", 16px, 19.2px);
	margin: 0 0 5px 0;
	color: $text-dark;
	text-transform: uppercase;
}

h2 {
	@include fontBuilder("Helvetica-Bold", 16px, 19.2px);
	margin: 0 0 5px 0;
	color: $text-dark;
	text-transform: uppercase;
}

h3 {
	@include fontBuilder("Helvetica-Bold", 11px, 13px);
	margin: 0 0 5px 0;
	color: $text-dark;
	text-transform: uppercase;
}

p {
	@include fontBuilder("Garamond", 15px, 20px, normal);
}

label {
	@include fontBuilder("Helvetica-Bold", 11px, 13px);
	text-transform: uppercase;
}

.text-light {
	color: $text-light;
}

.subhead {
	@include fontBuilder("Helvetica", 11px, 13.2px);
	margin: 0;
	color: $text-light;
}

.caseStudy-link {
	@include fontBuilder("Helvetica", 15px, 14pt, normal);
}

.upcoming-item {
	@include fontBuilder("Helvetica", 11px, 13.2px);
}

.glossary-spacer {
	@include fontBuilder("Helvetica", 14px, 16.8px);
	color: $brandBlue;
}

.glossary-term {
	@include fontBuilder("Helvetica-Bold", 12px, 15px);
}

.glossary-letter {
}

.abstract-body {
	@include fontBuilder("Garamond", 16px, 20px, normal);
	color: $text-dark;
}

.article-body {
	@include fontBuilder("Garamond", 15px, 20px, normal);
	color: $text-dark;

	strong {
		@include fontBuilder("Garamond-Bold", 14px, 16.8px);
	}
}

.tile-body {
	@include fontBuilder("Garamond", 15px, 20px, normal);
	color: $text-dark;
}

.glossary-def {
	@include fontBuilder("Garamond", 15px, 20px, normal);
}

.abstract {
	@include fontBuilder("Garamond", 14px, 16.8px);
	margin: 0 0 30px 0;
	color: $text-dark;
}

.archive-body {
	@include fontBuilder("Garamond", 16px, 20px);
	color: $text-light;

	p {
		@include fontBuilder("Garamond", 16px, 20px);
	}
}

.glossary-word {
	@include fontBuilder("Garamond", 20px, 24px);
}

.article-footer {
	@include fontBuilder("Garamond-Italic", 15px, 20px, normal);
	color: $text-light;

	p {
		@include fontBuilder("Garamond-Italic", 15px, 20px, normal);
	}
}

.byline {
	@include fontBuilder("Garamond-Italic", 15px, 20px, normal);
	margin: 0 0 30px 0;
	color: $text-dark;
}

.glossary-partOfSpeach {
	@include fontBuilder("Garamond-Italic", 20px, 24px);
}

.divider {
	border-top: 1px solid $dividerGray;
	margin: 0 0 28px 0;
}

.facetwp-search-wrap {
	display: inline-block;
	margin: 0;
	padding: 0;

	.facetwp-btn {
		display: inline-block;
		left: 5px;
		margin: 0;
		padding: 0;
		right: 0px;
		top: 50%;
		transform: translateY(-63%);
		width: 20px;
		z-index: 99;

		&:before {
			margin: 15px 0 0;
		}
	}
}

.archive-fabric-blog,
.search-results {
	.facetwp-search-wrap .facetwp-search {
		padding-left: 30px;
	}
}
