.navbar-default {
  position: relative;
  background-color: $white;
  margin-bottom: 11px;
  border-bottom: 1px solid $dividerGray;
  border-radius: 0;
  z-index: 1039;

  .caret-up {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid;
    vertical-align: middle;
  }

  .caret,
  .caret-up {
    display: none;
  }

  .search-form-header.form-inline {
    position: relative;
    margin-top: 9px;
    z-index: 10;

    .input-group {
      display: block;
    }

    .search-field-header {
      margin-bottom: 5px;
      height: 51px;
      padding-left: 55px;
      border-radius: 0;
    }

    .input-group-btn {
      position: static;

      .search-submit-header {
        position: absolute;
        left: 0;
        top: 3px;
        display: block;
        border: none;
        outline: none;
        z-index: 5;
        background: transparent;

        &:after {
          @include icomoon();
          padding-right: 5px;
          font-size: 28px;
          color: $gray-light;
          border-right: 1px solid $gray-light;
          content: "\e901";
          transition: .3s all;
        }

        &:hover, &:active {
          background: transparent;

          &:after {
            color: $brand-blue;
          }
        }
      }

      .searchbox-icon {
        display: none;
      }
    }
  }

  @media(min-width:$screen-md-max) {
    .search-wrapper {
      float: right;
    }

    .search-form-header.form-inline {
      position: relative;
      min-width: 50px;
      width: 80px;
      max-width: 200px;
      margin-top: 30px;
      height: 35px;
      float: right;
      transition: width 0.3s;

      &.searchbox-open {
        width: 100%;

        .search-field-header {
          visibility: visible;
        }

        .input-group-btn {

          .search-submit-header {
            background: transparent;
            outline: none;
            box-shadow: none;
            border: 1px solid #CCC;
            visibility: visible;
          }

          .icon-magnifying-glass {
            visibility: hidden;
          }
        }
      }

      .input-group {
        display: table;
      }

      .search-field-header {
        outline: 0;
        width: 100%;
        height: 35px;
        margin: 0;
        padding: 0 55px 0 20px;
        box-shadow: none;
        visibility: hidden;
      }

      .input-group-btn {

        .search-submit-header {
          width: 50px;
          height: 35px;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          padding: 0;
          font-size: 22px;
          outline: none;
          box-shadow: none;
          text-align: center;
          cursor: pointer;
          border: 1px solid #CCC;
          visibility: hidden;

          &:after {
            @include icomoon();
            border: none;
            content: "\e901";
            transition: .3s all;
          }

          &:hover, &:active {
            background: transparent;

            &:after {
              color: $brand-blue;
            }
          }
        }

        .searchbox-icon {
          display: block;

          .icon-magnifying-glass {
            position: absolute;
            top: 5px;
            right: 0;
            left: auto;
            width: 50px;
            height: 35px;
            padding: 0;
            font-size: 22px;
            color: #000;
            border: none;
            z-index: 5;
            text-align: center;
          }
        }
      }
    }
  }

  .navbar-nav {
    margin: 0 -15px;

    >li {
      font-family: 'Helvetica-Bold', sans-serif;
      font-size: 12px;
      text-transform: uppercase;

      @media (min-width: $screen-md-max) {
        margin: 52px 15px 0 15px;
        border-bottom: 7px solid $white;

        &.current-menu-parent {
          border-bottom: 7px solid red;
        }

        &:hover {
          border-bottom: 7px solid red;

          .dropdown-menu {
            display: block;
          }
        }
      }

      >a {
        padding: 0;
        color: $text-dark;

        &:hover,
        &:focus {
          background-color: $white;
          color: $text-dark;
        }
      }

      &.open {
        background-color: $white;

        @media (min-width: $screen-md-max) {
          border-bottom: 7px solid red;
        }

        >a {
          color: $text-dark;
          background-color: $white;

          &:hover,
          &:focus {
            background-color: $white;
            color: $text-dark;
          }
        }
      }
    }

    @media(min-width:$screen-md-max) {
      .dropdown-menu {
        top: 27px; // nav bar height - 52 offset;

        > li {
          font-family: 'Helvetica', sans-serif;
          font-size: 11px;

          > a {
            padding: 5px 10px;
            background-color: $white;
            color: $text-light;

            &:focus,
            &:hover {
              background-color: $white;
              color: $brandBlue;
            }
          }
        }

        > .active {
          > a {
            background-color: $white;
            color: $brandBlue;

            &:focus,
            &:hover {
              background-color: $white;
              color: $brandBlue;
            }
          }
        }
      }
    }

  }

  //bootstrap override
  >.container {
    .navbar-brand {
      margin: 0 30px 0 0;
    }
  }

  .navbar-brand {
    padding: 10px 0 0 0;
    height: auto;

    @media (min-width: $screen-sm-min) {
      margin-left: 0;
    }
  }

  @media (max-width:$screen-md-max) {
    .navbar-header {
      float: none;
    }

    .navbar-left,.navbar-right {
      float: none !important;
    }

    .navbar-toggle {
      position: relative;
      margin-top: 25px;
      margin-left: 41px;
      padding: 10px 35px 16px 0;
      display: block;
      border: none;
      background-color: transparent;
      z-index: 10;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }

      &.slide-active {

        span {
          background: transparent;

          &:before,
          &:after {
            top: 0;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      span,
      span:before,
      span:after {
        position: absolute;
        display: block;
        height: 3px;
        width: 25px;
        border-radius: 1px;
        background: #000;
        content: '';
        transition: all .1s ease-in-out;
      }

      span {

        &:before {
          top: -7px;
        }

        &:after {
          bottom: -7px;
        }
      }
    }

    .navbar-collapse {
      border-top: 1px solid transparent;
      margin-left: -15px;
      margin-right: -15px;
    }

    .navbar-collapse.collapse {
      display: none !important;
    }

    .navbar-nav {

      >li {
        position: relative;
        padding: 5px 10px;
        float: none;
        line-height: 60px;
        letter-spacing: 0.05em;
        border-top: 1px solid $lightGray20;
        background: $lightGray10;

        &.dropdown {
          background: #FFF;

          &:before {
            position: absolute;
            top: 0;
            right: 10px;
            content: '+';
            font-size: 24px;
            line-height: 50px;
            z-index: 5;
          }
        }

        >a {
          font-size: 13px;

          &:hover {
            background-color: transparent;
          }
        }


        &.open {

          &:before {
            content: '-';
          }

          .dropdown-menu {
            >li {

              >a {
                width: 100%;
                padding: 10px;
                border-top: 1px solid #ccc;
                font-size: 12px;
                color: $brand-blue;
                background-color: transparent;

                &:hover,
                &:focus {
                  color: $gray;
                  background-color: transparent;
                }
              }
            }
          }
        }

        .dropdown-menu {
          margin-left: -10px;
          margin-right: -10px;

          >li {

            >a {
              width: 100%;
              padding: 10px;
              border-top: 1px solid #ccc;
              font-size: 12px;
              color: $brand-blue;
            }
          }
        }

        >a {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .collapse.in{
      display:block !important;
    }
  }
}

.navbar-brand {
  img {
    width: 174px;
  }
}

// Tablet Breakpoint
@media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .navbar-brand {

    > img {
      width: 150px;
    }
  }

  // Search on tablet
  .search-form-header.form-inline {
    display: inline-block;
    width: 430px;
    margin-bottom: 12px;
    float: right;
  }

  // Dropdown Menu on tablet
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-default {

    .navbar-collapse {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  // Slide nav on tablet
  body.slide-active {
    overflow-x: hidden;

    .page-wrap {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0.5;
        z-index: 8;
        content: '';
      }
    }
  }

  .slide-nav {

    .navbar.slide-active {
      position: absolute;
      width: 293px;
      top: -1px;
      z-index: 1000;
    }

    .slidemenu {
      right: -100%;
      width: 293px;
      min-width: 0;
      position: absolute;
      padding-left: 0;
      z-index: 10;
      top: 76px;
      margin: 0;
    }

    .navbar-nav {
      min-width: 0;
      width: 100%;
      margin: 1px;
      background-color: #FFF;
    }

    .navbar-height-col {
      position: fixed;
      top: 0;
      height: 100%;
      width: 293px;
      right: -293px;
      background: #FFF;
      z-index: 9;
    }
  }
}


//homepage
body.home {
  .navbar-default {
    margin-bottom: 31px;
  }
}
